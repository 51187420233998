@import "colors.module";

.content {
  max-width: 720px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.title {
  white-space: pre-line;
  font-family: Inter-Bold;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 10px;
}

.description {
  white-space: pre-line;
  font-family: Inter-Regular;
  color: $grey;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
}

.comment {
  margin-top: 20px;
  margin-bottom: 40px;

  .title {
    font-family: Inter-Regular;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .textarea {
    min-height: 140px;
    border: 1px solid $gray-light-border;
    border-radius: 10px;
    resize: none;

    &::placeholder {
      color: #acabb2;
    }
  }

  .textareaContainer {
    position: relative;
  }

  .counter {
    display: none;
    position: absolute;
    right: 10px;
    bottom: 10px;
    height: 36px;
    width: 36px;
    text-align: center;
    line-height: 36px;
    margin: 0;
    font-size: 15px;
    color: $grey;
    border: 1px solid $gray-light-border;
    border-radius: 50%;
  }
}

.submit {
  margin-top: auto;
  margin-bottom: 40px;
}