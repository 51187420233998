@import "colors.module";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.returnAddress {
  display: flex;
  align-items: flex-start;
  padding: 0;
  padding-top: 30px;
  margin-bottom: 36.5px;
  width: calc(100% - 40px);
  border-top: 1px solid $gray-light-border;

  p {
    font-size: 18px;
    line-height: 24px;

    strong {
      font-family: 'Inter-Bold';
    }
  }
}

.checkbox, .checkboxError {
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  padding-top: 30px;
  margin-bottom: 36.5px;
  width: calc(100% - 40px);
  border-top: 1px solid $gray-light-border;

  div {
    display: flex;
  }

  input {
    position: relative;
    margin-top: 0;
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }

  label {
    display: inline-block;
    font-size: 18px;
  }
}

.checkboxError {
  color: $red-error;
}

.buttons {
  margin-top: auto;
  padding-bottom: 24px;
  height: fit-content;
  width: 100%;
  background-color: #fafafa;
  
  :global button.btn-light {
    display: block;
    border: none;
    width: 100%;
    height: 48px;
    font-family: Inter-Regular;
    font-size: 18px;
    line-height: 22px;
    background: none;
    color: $body-color;
    border-top: 1px solid $gray-light-border !important;
    border-radius: 0;

    &:last-child {
      border-bottom: 1px solid $gray-light-border;
    }
  }
}