@font-face {
  font-family: Inter-Regular;
  src: url(../fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: Inter-Medium;
  src: url(../fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(../fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: Inter-SemiBold;
  src: url(../fonts/Inter-SemiBold.ttf);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root-container {
  padding: 0;
}

*:focus {
  outline: none !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.tooltip.bg-white .arrow {
  color: #1A1A1A;
  background-color: white;
}

.tooltip.bg-white .tooltip-inner {
  color: #1A1A1A;
  background-color: white;
}

.copyPasteButton svg {
  width: 20px;
}

.copyPasteButton:hover path {
  stroke: white !important;
}

.cat {
  max-width: 200px;
}

.form-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.password-input.is-invalid ~ div > button > img {
  margin-right: 20px;
}

.password-input.is-invalid {
  padding-right: 65px !important;
}

.is-invalid {
  border-color: #ff6575 !important;
}

.invalid-feedback {
  color: #ff6575 !important;
}

.form-check-input.is-invalid ~ .form-check-label {
  color: #ff6575 !important;
}

.form-check-input.is-invalid ~ .form-check-label > a {
  color: #ff6575 !important;
}

/*.form-control.is-invalid {*/
/*  background: url("../images/error-icon.svg") no-repeat right 16px center !important;*/
/*}*/

.groupedForm input {
  border-bottom-width: 0;
  border-top-width: 0;
  color: #1A1A1A;
  border-color: #d9dbdf;
}

.groupedForm .custom-select {
  color: #1A1A1A;
}

.groupedForm .custom-select.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.75rem center/8px 10px no-repeat,
    url("../images/error-icon.svg") no-repeat right 30px center !important;
}

.groupedForm > *:last-child input {
  border-bottom-width: 1px;
}

.groupedForm > *:first-child input {
  border-top-width: 1px;
}

.groupedForm .line {
  width: 100%;
  height: 1px;
  background: #d9dbdf;
  position: absolute;
  left: 0;
  right: 0;
}

.groupedForm .line-top {
  top: -0.5px;
}

.groupedForm .line-bottom {
  bottom: -0.5px;
}

.groupedForm .is-invalid {
  border-bottom: none;
  border-top: none;
}

.groupedForm .is-invalid ~ div.line {
  background: #ff6575;
  z-index: 100;
}

.groupedForm > *:last-child .is-invalid {
  border-bottom: 1px solid #ff6575;
}

.groupedForm > *:first-child .is-invalid {
  border-top: 1px solid #ff6575;
}

.groupedForm-inline input {
  border-left-width: 0;
  border-right-width: 0;
}

.groupedForm-inline > *:first-child input {
  border-left-width: 1px;
}

.groupedForm-inline > *:last-child input {
  border-right-width: 1px;
}

.groupedForm-inline .line-vertical {
  width: 1px;
  height: 100%;
  background: #d9dbdf;
  position: absolute;
  top: 0;
  bottom: 0;
}

.groupedForm-inline .line-left {
  left: -0.5px !important;
}

.groupedForm-inline .line-right {
  right: -0.5px !important;
}

.groupedForm-inline .is-invalid {
  border-left: none;
  border-right: none;
}

.groupedForm-inline > *:last-child .is-invalid {
  border-right: 1px solid #ff6575;
}

.groupedForm-inline > *:first-child .is-invalid {
  border-left: 1px solid #ff6575;
}

.groupedForm-inline .is-invalid ~ .line-vertical {
  background: #ff6575;
  z-index: 100;
}

.groupedForm > div {
  margin-right: 0;
  margin-left: 0;
}

.groupedForm > div > div {
  padding-right: 0;
  padding-left: 0;
}

.addressField__placeholder {
  color: #bac2cb !important;
}

.text-overflow-words {
  overflow-wrap: break-word;
}

.text-overflow-any {
  overflow-wrap: anywhere;
  word-break: break-word;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.max-height-content {
  max-height: fit-content;
}

.absolute-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100vw;
  min-height: 100vh;
  height: max-content;
}

.display-none {
  display: none !important;
}

.spinner-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy-to-clipboard-textarea {
  position: fixed;
  top: -1000px;
}