button {
  &.btn-loading:not(.btn-link) {
    position: relative;

    & .btn-content {
      visibility: hidden;
    }

    & .btn-spinner {
      visibility: visible;
      position: absolute;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
    }
  }

  &.btn {
    border-radius: 5px;
    font-family: Inter-SemiBold;
    padding: 11px 20px;

    .btn-content {
      font-size: 15px;
      font-family: Inter-SemiBold;
    }

    &:disabled:not(.btn-link) {
      color: #ffffff !important;
      background-color: #BAC2CB !important;
      border-color: #BAC2CB !important;
    }

    &.btn-block {
      padding: 12px 0px;
    }

    &.btn-light {
      background-color: #fafafa;
    }

    &.btn-dark {
      background-color: #1a1a1a;
    }

    &.btn-link {
      font-family: Inter-Medium;
      display: inline;
      border: none;
      box-shadow: none;

      .btn-content {
        font-size: 14px;
      }

      .btn-content.counter-offer-text {
        font-size: 16px;
      }

      & .btn-spinner {
        width: 1rem;
        height: 1rem;
        border-width: 0.15rem;
        margin-left: 0.5rem;
        margin-bottom: 0.1rem;
      }
    }
  }

  &.button-link {
    background: none;
    border: none;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }

}
