@import "colors.module";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 0;
  border-top: 1px solid #E0E2E6;
}

.amountField {
  border: 1px solid $gray-light-border;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;
}

.prepend, .append {
  button.minusBtn, button.plusBtn {
    border: none !important;
    border-top: none !important;
    padding: 0;
    width: 35px;
    height: 35px;
    border-radius: 50% !important;
    margin: 0 !important;

    &:focus, &:active {
      box-shadow: none !important;
    }
  }
}

.input {
  font-size: 38px;
  font-family: Inter-Bold;
  border: none;
  margin: 0 10px;
  padding: 0;
  text-align: center;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
}

.help {
 font-size: 14px;
}

.submit {
  width: 100%;
}