@import "colors.module";

.container {
  max-width: 720px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  
  .title {
    font-family: Inter-Bold;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .text {
    font-size: 18px;
    line-height: 24px;
    color: #7B7B82;
    margin-bottom: 30px;
  }

  .label {
    font-size: 14px;
    line-height: 16.8px;
    margin-bottom: 10px;
  }

  .formGroup {
    margin-bottom: 20px;
  }

  .input {
    min-height: 48px !important;
    border: 1px solid $gray-light;
    font-size: 18px;
    line-height: 24px;

    &::placeholder, &.selectPlaceholder {
      color: #ACABB2;
    }

    option {
      color: $body-color;

      &:first-child {
        color: $gray-medium !important;
      }
    }
  }

  .button {
    width: 100%;
    margin-bottom: 32px;

    span {
      font-size: 18px !important;
      line-height: 24px;
    }
  }
}