.text-title {
  font-family: Inter-Bold;
  font-size: 22px;
    line-height: 26.4px;
}

.text-title--modified {
  font-family: Inter-Bold;
  font-size: 24px;
    line-height: 28.8px;
}

.text-main-bold {
  font-family: Inter-Bold;
  font-size: 16px;
    line-height: 22px;
}

.text-main-semi-bold {
  font-family: Inter-SemiBold;
  font-size: 16px;
    line-height: 22px;
}

*,
.text-main {
  font-family: Inter-Medium;
  font-size: 16px;
    line-height: 22px;
}

.text-sub {
  font-family: Inter-Medium;
  font-size: 14px;
    line-height: 18px;
}

.text-small {
  font-family: Inter-Medium;
  font-size: 12px;
    line-height: 14.4px;
}

.text-cta {
  font-family: Inter-SemiBold;
  font-size: 15px;
    line-height: 18px;
}

.text-underline {
  text-decoration: underline;
}
