@import "colors.module";

.addressField {

  &__control {
    height: 48px;
    cursor: text !important;
    background-image: url("~assets/images/address-field-icon.svg");
    background-repeat: no-repeat;
    background-position: left 16px center;
    background-size: 26px;
    border-color: $gray-light !important;

    &--is-focused {
      border-color: var(--blue) !important;
      box-shadow: 0 0 0 0.2rem rgba(0, 75, 180, 0.25) !important;
    }
  }

  &__value-container {
    margin-left: 40px;
  }

  &__placeholder, &__single-value {
    font-size: 18px;
    line-height: 24px;
  }

  &__placeholder {
    color: #ACABB2;
  }

  &__clear-indicator {
    cursor: pointer;
    margin-right: 16px;
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
  }

  &__single-value {
    color: $primary-darken !important;
    padding-right: 5px;
  }
}
