@import "colors.module";

.container {
  position: relative;
  width: 100%;
  aspect-ratio: 335/140;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $gray-light;

  img {
    min-height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .editDeleteBtnContainer {
    right: 6px;
    top: 6px;
    position: absolute;
    display: flex;
    flex-direction: row;

    button + button {
      margin-left: 12px;
    }
  }

  .editBtn, .deleteBtn {
    background: $white;
    border: none !important;
    width: 26px;
    height: 26px;
    padding: 0 !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center !important;

    svg {
      width: 26px;
      height: 26px;
    }
  }

  .downloadBtn {
    background: transparent;
    position: absolute;
    padding: 0 !important; 
    display: flex;
    align-items: center;
    justify-content: center !important;
    padding: 5px;
    border: none;
    width: 100%;
    height: 100%;
    
    svg {
      width: 40px;
      height: 40px;
    }
    
    &:hover {
      div {
        opacity: 1;
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $gray-light;
      opacity: .5;
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
  }
}