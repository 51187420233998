@import "colors.module";

.content {
  max-width: 720px;
  margin: auto 0;
  display: flex;
  flex-direction: column;
}

.title {
  white-space: pre-line;
  font-family: Inter-Bold;
  font-size: 26px;
  line-height: 30px;
  margin: 0 20px 10px;
}

.description {
  white-space: pre-line;
  font-family: Inter-Regular;
  color: $grey;
  font-size: 18px;
  line-height: 22px;
  margin: 0 20px;
}

.buttons, .buttonsPlaceholder {
  margin-top: 36.5px;
  padding-bottom: 24px;
  height: fit-content;
  background-color: #fafafa;
  
  :global button.btn-light {
    display: block;
    border: none;
    width: 100%;
    height: 48px;
    font-family: Inter-Regular;
    font-size: 18px;
    line-height: 22px;
    color: $body-color;
    border-top: 1px solid $gray-light-border !important;
    border-radius: 0;

    &:last-child {
      border-bottom: 1px solid $gray-light-border;
    }
  }
}

.response {
  display: flex;
  justify-content: flex-end;
  margin: 20px;

  div {
    position: relative;
    max-width: 90%;
  }

  p {
    padding: 8px 16px;
    border-radius: 20px;
    background-color: var(--primary);
    color: var(--text-color-on-primary-bg);
    display: inline-block;
    margin: 0;
    font-family: Inter-Regular;
    font-size: 18px;
    line-height: 22px;
    white-space: pre-line;

    strong {
      font-family: 'Inter-Bold';
    }
  }

  button {
    position: absolute;
    margin: 0;
    padding: 0;
    left: -15px;
    bottom: 0;
    border-radius: 50%;
    width: 26px;
    height: 26px;

    svg {
      width: 16px;
      height: 16px;
      margin-bottom: 2px;
    }
  }
}

.evidence {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin: 20px 20px;
  max-width: 66%;
  border: 1px solid $gray-light-border;
  padding: 5px;
  border-radius: 10px;
  background-color: #fafafa;

  &:only-child {
    margin-top: 0;
  }

  p {
    margin: 0;
    font-size: 14px;
    white-space: pre-line;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  & > div, img {
    aspect-ratio: 4/3 !important;
  }
}