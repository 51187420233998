@import "colors.module";

.content {
  display: flex;
  align-items: center;
  padding: 20px;

  :global .modal-body {
    width: 100%;
  }

  .title {
    font-family: Inter-Bold;
    font-size: 26px;
    margin: 20px 0 10px;
    text-align: center;
  }

  .text {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }

  .button {
    display: block;
    width: 100%;

  }
  .button + .button {
    margin-top: 10px;
  }
}