@import "colors.module";

.container {
  min-height: 100vh;
  max-width: 720px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 19px 20px;
  border-bottom: 1px solid $gray-light-border;

  .title {
    font-size: 14px;
    font-family: Inter-Bold;
    line-height: 22px;
    margin: 0;
  }

  .goBack, .exit {
    height: 23px;
    width: 23px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    border: 0;
    padding: 0;
    line-height: initial;
    background-color: transparent !important;
  }

  .goBack {
    left: 20px;
  }

  .exit {
    right: 20px;

    svg {
      height: 13px;
      width: 13px;
    }
  }
}

.statusBar {
  display: flex;
  background-color: var(--primary-alpha1);
  padding: 12px;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 0.25em;
    max-height: 16px;
  }

  p {
    color: var(--primary);
    font-family: Inter-Bold;
    font-size: 16px;
    margin: 0;
  }
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 30px;
}

.messagesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;

  .titlePending, .titleStart {
    font-family: Inter-Bold;
    font-size: 26px;
    margin-bottom: 30px;
    align-self: flex-start;
  }
  .titlePending {
    align-self: center;
    text-align: center;
  }

  .textStart, .textMessage, .textDate {
    color: $grey;
    font-size: 18px;
    align-self: center;
    text-align: center;
    white-space: pre-line;
    margin: 0;
    margin-bottom: 30px;

    strong {
      font-family: Inter-Bold;
      font-weight: normal;
      font-size: 18px;
    }
  }

  .textStart {
    text-align: left;
    align-self: flex-start;

  }

  .textMessage a {
    font-size: 18px;
    color: $grey;
    text-decoration: underline;
  }

  &:last-child() {
    margin-bottom: 30px;
  }
}

.stepsContainer {
  margin-top: auto;

  .buttons {
    margin-top: 36.5px;
    padding-bottom: 24px;
    height: fit-content;
    background-color: #fafafa;
    
    
    :global button.btn-light {
      display: block;
      border: none;
      width: 100%;
      height: 48px;
      font-family: Inter-Regular;
      font-size: 18px;
      line-height: 22px;
      color: $body-color;
      border-top: 1px solid $gray-light-border !important;
      border-radius: 0;
  
      &:last-child {
        border-bottom: 1px solid $gray-light-border;
      }
    }
  }
}

.ellipsis {
  height: 40px;
  margin: 0 20px;
}