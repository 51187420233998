@import "colors.module";

.container {
  padding: 40px 20px 20px;;

  :global .modal-footer button.btn.btn-light {
    background-color: white;
    border: 0;
  }
}

.title {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  width: 100%;
  font-family: Inter-Bold;
  margin-bottom: 10px;
}

.text {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.button {
  display: block;
  width: 100%;
}