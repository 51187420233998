@import "colors.module";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 0;
  border-top: 1px solid #E0E2E6;
}

.input {
  min-height: 84px;
  border: 1px solid $gray-light-border;
  border-radius: 10px;
  margin-bottom: 10px;
  resize: none;
}

.submit {
  width: 100%;
}