@import "colors.module";

.container {
  margin-top: 20px;
  width: 100%;
  aspect-ratio: 335/140;
  border: 1px dashed $gray-light-border;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  button.addButton {
    border: 1px solid $body-color !important;
    position: relative;

    svg {
      margin-right: 10px;
      height: 20px;
      width: 20px;
    }

    :global {
      .form-label, .form-group {
        margin: 0;
        cursor: pointer;
      }
      
      .form-file {
        position: absolute !important;
        width: 0 !important;
        opacity: 0 !important;
      }
    }
  }
}