@import "colors.module";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.icon {
  width: 162px;
  height: 80px;
  margin-top: auto;
  margin-bottom: 30px;
}

.title {
  font-family: Inter-Bold;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
}

.text {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: auto;
  text-align: center;
}

.submit {
  width: 100%;
  margin-bottom: 40px;
}