@import "colors.module";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $gray-light-border;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 90%;

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-of-type(odd) {
    align-self: flex-start;
  }

  &:nth-of-type(even) {
    align-self: flex-end;
  }

  &.isBuyer:nth-of-type(odd) {
    align-self: flex-end;
  }

  &.isBuyer:nth-of-type(even) {
    align-self: flex-start;
  }

  &.recap {
    border: none;
    width: 100%;
  }
}

.issueType {
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-light-border;
  }

  &.recap {
    border: 1px solid $gray-light-border;
    background-color: #fafafa;
    padding: 10px;
    border-radius: 10px;
  }

  .label {
    font-size: 18px;
    color: $grey;
    margin-bottom: 8px;
  }

  .title {
    font-size: 26px;
    font-family: Inter-Bold;
    margin-bottom: 10px;
  }

  .description {
    font-size: 18px;
    color: $grey;
    margin-bottom: 0;
  }
}

.summary {
  padding-top: 30px;
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-light-border;
  }

  &:first-child {
    padding-top: 10px;
  }

  .line {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 0;
    }

    .icon {
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 14px;

      svg {
        max-height: 20px;
        max-width: 20px;
      }
    }

    .title {
      font-size: 18px;
      font-family: Inter-Bold;
      margin-right: 0.25rem;
    }

    .value {
      font-size: 18px;
    }
  }
}

.data {
  padding-top: 30px;
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-light-border;
  }

  .line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    hyphens: auto;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 0;
    }

    .title {
      width: 66%;
      font-size: 18px;
    }

    .value {
      text-align: center;
      width: 27%;
      padding: 4px 10px;
      border-radius: 5px;
      background-color: var(--primary);
      color: var(--text-color-on-primary-bg);
      font-size: 14px;
      font-family: Inter-Bold;
    }
  }
}

.medias {
  padding-top: 30px;
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-light-border;
  }

  .media {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    border: 1px solid $gray-light-border;
    padding: 5px;
    border-radius: 10px;
    
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }
}

.totals {
  padding-top: 30px;
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-light-border;
  }

  .line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      font-size: 18px;
    }

    &:last-child {
      border-top: 1px solid $gray-light-border;
      padding-top: 20px;
      margin-bottom: 0;

      .title, .value {
        font-family: Inter-Bold;
      }
    }

    p {
      margin-bottom: 0;
    }

    svg {
      margin-right: 14px;
    }
  }
}